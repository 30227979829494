<template>
  <figure
    class="relative block w-full overflow-hidden p-0 before:block before:pt-[56.25%]"
  >
    <div class="absolute bottom-0 left-0 top-0 h-full w-full border-0">
      <div class="bg-dark relative block h-full w-full">
        <iframe
          title="YouTube video"
          width="560"
          height="315"
          :src="source"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          class="lazy absolute left-0 top-0 block h-full w-full bg-none"
          loading="lazy"
          allowfullscreen
        ></iframe>
        <img
          v-show="source === ''"
          :src="getPoster"
          class="lazy absolute left-0 top-0 block h-full w-full bg-none"
          loading="lazy"
          alt="YouTube video"
        />
        <button
          v-show="source === ''"
          class="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 !outline-none"
          @click="play"
        >
          <svg
            width="74"
            height="64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="scale-100"
          >
            <title>Play</title>
            <path d="M74 32L19 60V4l55 28z" fill="#fff" />
          </svg>
        </button>
      </div>
    </div>
  </figure>
</template>

<script setup lang="ts">
const props = defineProps<{
  videoId: string
  poster: string
}>()

const _videoId = computed(() => {
  if (props.videoId.match(/^http/)) {
    const res = props.videoId.match(/[vV]=([^&$\n\s]*)/)

    if (res) {
      return res[1] || props.videoId
    }
  }

  return props.videoId
})

const getPoster = computed(() => {
  return props.poster ===
    'https://i.ytimg.com/vi_webp/' + _videoId.value + '/sddefault.webp'
    ? 'https://i.ytimg.com/vi_webp/' + _videoId.value + '/sddefault.webp'
    : props.poster
      ? props.poster
      : 'https://i3.ytimg.com/vi/' + _videoId.value + '/maxresdefault.jpg'
})

const source = ref('')

function play() {
  source.value =
    'https://www.youtube.com/embed/' + _videoId.value + '?autoplay=1'
}
</script>
